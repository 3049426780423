var Morphing = (function ($) {

    var init = function () {

        var morph = [];
        $('.commandes a').each(function () {
            morph.push($(this).attr('href').replace('#', ''));

        });

        var IntID = setTimerAnim();

        function setTimerAnim() {
            var timer = setInterval(morphing, 80000);
            return timer;
        }

        function stopAnim() {
            clearInterval(IntID);
        }

        var morph_length = morph.length;

        var i = 0;

        function morphing() {
            $('.pc .inner').removeAttr('id').attr('id', morph[i]);
            $('.commandes a').removeClass('active_morph');
            $('.banner.home_pc_morph > .bg').removeClass('btool').removeClass('borange').removeClass('bbleuciel');
            $('.commandes a[href="#' + morph[i] + '"]').addClass('active_morph');
            $('#textsouspc h2').fadeOut(300);
            $('#textpc > div').fadeOut(300);
            $('#textsouspc h2[id=' + morph[i] + 'h2]').delay(700).fadeIn(300);
            $('#textpc > div[id=' + morph[i] + 'text]').delay(700).fadeIn(900);
            $('.pc .inner .blockin #pcanim').fadeOut(300).removeClass();
            if (i == 0) {
                $('.banner.home_pc_morph > .bg').addClass('btool');
                $('.pc .inner .blockin #pcanim').delay(300).empty().append(
                    '<div class="anim_header_pc ecran">'+
                        '<div class="header_pc block">'+
                            '<div class="votre_logo icon-logo-clq w2">'+
                                'cliquez-la.com'+
                            '</div>'+
                            '<i class="icon-burger w2"></i>'+
                        '</div>'+
                        '<div class="conteneur_left">'+
                            '<div class="titrep w1 tal blocktext block block_reverse">'+
                                'aad'+
                            '</div>'+
                            '<ul class="liste w1 blocktext">'+
                                '<li class="block">'+
                                    'az'+
                                '</li>'+
                                '<li class="block">'+
                                    'azz'+
                                '</li>'+
                                '<li class="block">'+
                                    'az'+
                                '</li>'+
                                '<li class="block">'+
                                    'z a'+
                                '</li>'+
                            '</ul>'+
                            '<div class="ico_left">'+
                                '<div class="block w4 icon-phone"></div>'+
                                '<div class="block w4 icon-likew"></div>'+
                                '<div class="block w4 icon-envelope-o"></div>'+
                                '<div class="block w4 icon-map"></div>'+
                            '</div>'+
                        '</div>'+
                        '<div class="conteneur">'+
                            '<div class="image w2 block">'+
                                '<div class="titrep blocktext block block_reverse">'+
                                    'a ad'+
                                '</div>'+
                                '<div class="inner_block">'+
                                    '<div class="icon-shop"></div>'+
                                    '<span class="blocktext">'+
                                        'abcd er fgd 1ad q s'+
                                    '</span>'+
                                '</div>'+
                            '</div>'+
                            '<div class="image w2 block">'+
                                '<div class="titrep blocktext block block_reverse">'+
                                    'e f sd'+
                                '</div>'+
                                '<div class="inner_block">'+
                                    '<div class="icon-attache-case"></div>'+
                                    '<span class="blocktext">'+
                                        'a c erg f fef s qs d'+
                                    '</span>'+
                                '</div>'+
                            '</div>'+
                            '<div class="slider w1 block">'+
                                '<div class="inner_block">'+
                                    '<div class="icon-left"></div>'+
                                    '<div class="icon-img-thumb"></div>'+
                                    '<div class="icon-right"></div>'+
                                '</div>'+
                            '</div>'+
                        '</div>'+
                    '</div>'
                ).delay(700).fadeIn(300, function () {
                    $(this).addClass('start');
                });
            } else if (i == 1) {
                $('.banner.home_pc_morph > .bg').addClass('borange');
                $('.pc .inner .blockin #pcanim').delay(300).empty().append(
                    '<div class="anim_header_pc tel">'+
                        '<div class="header_pc block">'+
                            '<div class="votre_logo icon-logo-clq w2"> cliquez-la.com </div>'+
                            '<i class="icon-burger w2"> </i>'+
                        '</div>'+
                        '<div class="conteneur">'+
                            '<div class="image w1 block">'+
                                '<div class="titrep blocktext block block_reverse">e f sd</div>'+
                                '<div class="inner_block">'+
                                    '<div class="icon-shop"></div>'+
                                    '<span class="blocktext">a c erg f fef s qs d</span>'+
                                '</div>'+
                            '</div>'+
                            '<div class="image w2 block carre">'+
                                '<div class="icon-ecommerce"></div>'+
                                '<span class="blocktext">abcd</span>'+
                            '</div>'+
                            '<div class="image w2 block carre">'+
                                '<div class="icon-ecommerce"></div>'+
                                '<span class="blocktext">abcd</span>'+
                            '</div>'+
                            '<div class="image w2 block carre">'+
                                '<div class="icon-ecommerce"></div>'+
                                '<span class="blocktext">abcd</span>'+
                            '</div>'+
                            '<div class="image w2 block carre">'+
                                '<div class="icon-ecommerce"></div>'+
                                '<span class="blocktext">abcd</span>'+
                            '</div>'+
                            '<div class="ico_left">'+
                                '<div class="block w4 icon-phone"></div>'+
                                '<div class="block w4 icon-likew"></div>'+
                                '<div class="block w4 icon-envelope-o"></div>'+
                                '<div class="block w4 icon-map"></div>'+
                            '</div>'+
                        '</div>'+
                    '</div>'                    
                ).delay(700).fadeIn(300, function () {
                    $(this).addClass('start');
                });
            } else if (i == 2) {
                $('.banner.home_pc_morph > .bg').addClass('bbleuciel');
                $('.pc .inner .blockin #pcanim').delay(300).empty().append(
                    '<div class="anim_header_pc tablet">'+
                        '<div class="header_pc block">'+
                            '<div class="votre_logo icon-logo-clq w2"> cliquez-la.com </div>'+
                            '<i class="icon-burger w2"> </i>'+
                        '</div>'+
                        '<div class="conteneur">'+
                            '<div class="slider w1 block block_reverse">'+
                                '<div class="inner_block">'+
                                    '<div class="icon-left"></div>'+
                                    '<div class="icon-webmarketing">'+
                                        '<div class="blocktext">es r</div>'+
                                    '</div>'+
                                    '<div class="icon-right"></div>'+
                                '</div>'+
                            '</div>'+
                            '<div class="image w3 block carre">'+
                                '<div class="icon-user"></div>'+
                            '</div>'+
                            '<div class="image w3 block carre">'+
                                '<div class="icon-graph"></div>'+
                            '</div>'+
                            '<div class="image w3 block carre">'+
                                '<div class="icon-eur"></div>'+
                            '</div>'+
                            '<div class="image w3 block carre">'+
                                '<div class="icon-edit"></div>'+
                            '</div>'+
                            '<div class="image w3 block carre">'+
                                '<div class="icon-wifi"></div>'+
                            '</div>'+
                            '<div class="image w3 block carre">'+
                                '<div class="icon-share"></div>'+
                            '</div>'+
                            '<div class="image w3 block carre">'+
                                '<div class="icon-link"></div>'+
                            '</div>'+
                            '<div class="image w3 block carre">'+
                                '<div class="icon-portail"></div>'+
                            '</div>'+
                            '<div class="image w3 block carre">'+
                                '<div class="icon-application"></div>'+
                            '</div>'+
                        '</div>'+
                    '</div>'
                ).delay(700).fadeIn(300, function () {
                    $(this).addClass('start');
                });
            }
            var new_morph = morph[i++];
            if (i >= morph_length) {
                i = 0;
            }
        }
        $('.commandes a').click(function () {
            var voirmorph = $(this).attr('href').replace('#', '');
            clearInterval(IntID);
            IntID = setTimerAnim();
            if ($(this).index() == i - 1) {
                return false;
            } else {
                i = $(this).index();
                morphing();
            }
            return false;
        });
        morphing();
        /*$('.commandes a').click(function(){
            var voirmorph = $(this).attr('href').replace('#','');
            $('.pc .inner').removeAttr('id').attr('id',voirmorph);
            $('.commandes a').removeClass('active_morph');
            $(this).addClass('active_morph');   
            clearInterval(IntID);
            IntID = setTimer();
            $('#textpc > div').fadeOut(600);
            $('#textsouspc h2').fadeOut(300);
            $('#textpc > div[id='+voirmorph+'text]').delay(1150).fadeIn(900);
            $('#textsouspc h2[id='+voirmorph+'h2]').delay(1150).fadeIn(300);
            return false;
        });*/
        
    };

        /* =============== export public methods =============== */
    return {
        init: init
    };

})(jQuery);
