var Footer = (function ($) {

    var init = function () {

        $('.coord_footer .mail_foot .plain .icon-news').click(function () {
            $(this).addClass('aviongo');
            setTimeout(function () {
                $('.coord_footer .mail_foot .plain .icon-news').removeClass('aviongo');
            }, 10000);
        });

    };

    /* =============== export public methods =============== */
    return {
        init: init
    };

})(jQuery);