var Forms = (function ($) {

    var init = function () {

        $('.cck_text').append('<div class="bar"></div>');
        $('.cck_textarea').append('<div class="bar"></div>');
        $('.cck_search_generic').append('<div class="bar"></div>');

        $('.cck_form input').focusin(function () {
            if (!$(this).parent().parent().find('.cck_label label').hasClass('focused')) {
                $(this).parent().parent().find('.cck_label label').addClass('focused');
            }
        });

        if (($('.cck_form input').val() != '') && (!$(this).parent().parent().find('.cck_label label').hasClass('focused'))) {
            $(this).parent().parent().find('.cck_label label').addClass('focused');
        };

        $('.cck_form input').focusout(function () {
            if ($(this).val() == '') {
                $(this).parent().parent().find('.cck_label label').removeClass('focused');
            }
        });

        $('.cck_form textarea').focusin(function () {
            if (!$(this).parent().parent().find('.cck_label label').hasClass('focused')) {
                $(this).parent().parent().find('.cck_label label').addClass('focused');
            }
        });

        if (($('.cck_form textarea').val() != '') && (!$(this).parent().parent().find('.cck_label label').hasClass('focused'))) {
            $(this).parent().parent().find('.cck_label label').addClass('focused');
        };

        $('.cck_form textarea').focusout(function () {
            if ($(this).val() == '') {
                $(this).parent().parent().find('.cck_label label').removeClass('focused');
            }
        });

    };

    /* =============== export public methods =============== */
    return {
        init: init
    };

})(jQuery);