jQuery.noConflict(true);

var $window = jQuery(window);

jQuery(document).ready(function ($) {

    NavLeft.init();

    Morphing.init();

    Price.init();

    Carousel.init();

    Scroll.init();

    Forms.init();

    Modal.init();

    Footer.init();

    setTimeout(function () {
        $('body').addClass('loaded');
    }, 2000);
});