var Price = (function ($) {

    var init = function () {

     //////////////////////////////
     //////////////////////////////
     ////////// Tableau prix
     var index_tab = [];
     $('.tab3 .tab_detail .tab').each(function (index, el) {
         index_tab.push($(this).outerHeight());
     });

     for (var i in index_tab) {
         $('.tab1 .tab_detail .tab').eq(i).css('height', +index_tab[i]);
         $('.tab2 .tab_detail .tab').eq(i).css('height', +index_tab[i]);
     }
     ////////// FIN Tableau prix
     //////////////////////////////
     //////////////////////////////

    };

    /* =============== export public methods =============== */
    return {
        init: init
    };

})(jQuery);