var Modal = (function ($) {

    var init = function () {

        if ($('body > div.unlogged').length) {
            var portail = $('#menu_top .menu1 .item-137 a');
            portail.attr('href', '#connexion').attr('data-effect', 'mamodal').attr('data-target', '#connexion');
            var portail = $('#menu_wrapper .menu .item-116 a');
            portail.attr('href', '#connexion').attr('data-effect', 'mamodal').attr('data-target', '#connexion');
        }

        var recherche = $('#menu_wrapper .menu .item-122 a');
        recherche.attr('href', '#rechercher').attr('data-effect', 'mamodal').attr('data-target', '#rechercher');

        var formulaire = $('#menu_wrapper .menu .item-132 a');
        formulaire.attr('href', '#formulaire').attr('data-effect', 'mamodal').attr('data-target', '#formulaire');

        // Modal maison
        var mamodal_bouton = $('a').filter('[data-effect=mamodal]');

        $(mamodal_bouton).on('click', function () {

            var windowheight = $(document).height(),
                mamodal = $(this).data('target').replace('#', '');


            $('#' + mamodal + '-container').load('index.php?tmpl=module&module_pos=' + mamodal);
            $('#' + mamodal).addClass('gogo');
            $('#' + mamodal + '-container').addClass('modal_on');
            $('body').addClass('modal-open');
            $('body').append('<div style="height:' + windowheight + 'px;" class="mamodal-backdrop gogo"></div>');


            if ($('.modal_on').length > 0) {
                $(document).mouseup(function (e) {
                    var container = $('.modal-body .modal_on'),
                        bkground = $('body div.mamodal-backdrop');

                    if (container.has(e.target).length === 0) {

                        container.parent().parent().parent().parent().removeClass('gogo');
                        bkground.removeClass('gogo');
                        $('.modal_on').children().remove();
                        $('#' + mamodal + '-container').removeClass('modal_on');
                        $('body').removeClass('modal-open');
                        setTimeout(function (event) {
                            bkground.remove();
                        }, 1000);
                    }
                    e.stopPropagation();
                });
            }
            return false;
        });

    };

    /* =============== export public methods =============== */
    return {
        init: init
    };

})(jQuery);