var Scroll = (function ($) {

    var init = function () {

        var $pcinview = $('.pc .inner');
        $pcinview.each(function () {
            var $self = $(this);
            if (($(this).scrollTop() + $window.height() / 1.5) >= ($self.offset().top)) {
                $self.addClass('inview');
            } else {
                $self.removeClass('inview');
            }
            $window.scroll(function () {
                if (($(this).scrollTop() + $window.height() / 1.5) >= ($self.offset().top)) {
                    $self.addClass('inview');
                } else {
                    $self.removeClass('inview');
                }
            });
        });

        /*    
        var $intros = $('.intro-portfolio.item.portfolio');
        $intros.each(function() {
            var $self = $(this);
                if (($(this).scrollTop() + $window.height()) >= ($self.position().top)){
                    $self.addClass('inview');
                } else {
                    $self.removeClass('inview');
                }
            $window.scroll(function () {
                if (($(this).scrollTop() + $window.height()) >= ($self.position().top)){
                    $self.addClass('inview');
                } else {
                    $self.removeClass('inview');
                }
            });
        });
        */

        //////////////////////////////////////////

        $(window).scroll(function () {
            var wintop = $(window).scrollTop(),
                docheight = $(document).height(),
                winheight = $(window).height(),
                scrolled = (wintop / (docheight - winheight)) * 100;

            if (scrolled >= 90) {
                $('.coucou').addClass('bas');
            }
            if (scrolled < 91) {
                $('.coucou').removeClass('bas');
            }
            $('.scroll-line').css('width', (scrolled + '%'));
        });

        /////////////////////////////////////////

        var offset = 600;
        var offsetmenu = 100;
        var duration = 500;

        $(window).scroll(function () {
            if ($(this).scrollTop() >= offset) {
                $('.back-to-top').css({
                    opacity: '1',
                    transition: '0.3s'
                });
            } else {
                $('.back-to-top').css({
                    opacity: '0',
                    transition: '0.3s'
                });
            }
            if ($(this).scrollTop() >= offsetmenu) {
                $('#menu_top').addClass('affix');
            } else {
                $('#menu_top').removeClass('affix');
            }
        });

        $('.back-to-top').click(function (event) {
            event.preventDefault();
            $('body').animate({
                scrollTop: 0
            }, duration);
            return false;
        });


    };

    /* =============== export public methods =============== */
    return {
        init: init
    };

})(jQuery);