var NavLeft = (function ($) {

    var init = function () {

        $('#menuleft').click(function () {
            var topwindow = $(window).scrollTop(),
                windowheight = $(document).height();
            $('#menu_wrapper').css('top', topwindow);
            $(this).addClass('show_menu');
            $('body').addClass('show_menu');
            $('body').append('<div style="height:' + windowheight + 'px;" class="mamodal-backdrop gogo"></div>');

            $(document).mouseup(function (e) {
                var container = $('#menu_wrapper'),
                    bkground = $('body div.mamodal-backdrop');

                if (container.has(e.target).length === 0) {
                    $('body').removeClass('show_menu');
                    bkground.removeClass('gogo');
                    $('#menuleft').removeClass('show_menu');
                    setTimeout(function (event) {
                        bkground.remove();
                    }, 600);
                }
                $('#menu_wrapper .nav.menu a, #fermer_menu').click(function (event) {
                    //console.log("sup");
                    $('body').removeClass('show_menu');
                    bkground.removeClass('gogo');
                    $('#menuleft').removeClass('show_menu');
                    setTimeout(function (event) {
                        bkground.remove();
                    }, 600);
                });
                e.stopPropagation();
            });
            return false;
        });
    };

    /* =============== export public methods =============== */
    return {
        init: init
    };

})(jQuery);