var Carousel = (function ($) {

    var init = function () {
       // On créer le conteneur du menu et les boutons previous next du carrousel
       $('.module_portolio_home .cck_module_list > div').attr('id', 'carrousel').parent().addClass('wrapper_carrousel');
       $('.bottom_intro_home').append('<div class="navigation"></div>');
       var slider_block = $('.bottom_intro_home');
       var hauteurblock = $('#carrousel .portfolio_home:first').outerHeight();
       var largeurblock = $('#carrousel .portfolio_home:first').outerWidth();
       var wrapper = $('.module_portolio_home .wrapper_carrousel');
       var carrousel = $('#carrousel', wrapper);
       var index_slider = [];

       wrapper.css('height', hauteurblock);
       var navigation = $('.navigation');
       var slides = $('#intro_pc');

       var slide = $('#carrousel .portfolio_home');
       var nbSlide = slide.length;
       var nbre_slide_parpage = 2;

       var table_titre = [];
       var titre_slider = $('.intro-titre', slide);
       titre_slider.each(function () {
           table_titre.push($(this).attr('title'));
           /*
             table_titre.push($(this).text().replace(/\n/g, ''));*/
       });
       //console.log(table_titre);

       var couleur_slider = [];
       //console.log(couleur_slider);
       /*var filtre_slide = $('#filtre_siteweb');
       filtre_slide.autocomplete({
           source: table_titre,
           inLength : 2
       });*/

       // On fixe la taille de carrousel et des slides
       function size_slider() {
           //carrousel.css({height: hauteurblock,width: largeurblock * nbSlide});
           carrousel.css({
               height: hauteurblock,
               width: ($(window).width() / 3) * nbSlide
           });
           //console.log(nbSlide);
           $('.portfolio_home', carrousel).each(function () {
               var numindex = $(this).index();
               //$(this).height(hauteurblock);
               ////////////////////////////
               //////Dimensionnement à prévoir pour mobile
               if (nbre_slide_parpage == 2) {
                   //$(this).width(wrapper.width() / 3).height(hauteurblock);
                   $(this).width($(window).width() / 3).height(hauteurblock);
               }
               index_slider.push($(this));
               $(this).attr('id', 'slider_pc_' + (numindex + 1));

               couleur_slider.push($(this).attr('data-couleur'));
           });
       }
       size_slider();
       /*
       $('#carrousel .portfolio_home').each(function() {
           var numindex = $(this).index();
           $('.wrapper_carrousel .navigation > ul > li a.icon-right').parent().before('<li><a href="#' + $(this).attr('id') + '" class="carrousel_link pages">' + (numindex + 1) + '</a></li>');
       });*/
       $('.navigation', '#intro_pc').append('<ul><li><a href="#" title="Précédent" class="icon-left carrousel_link"></a></li><li class="carrousel_link total_slide"></li><li class="suivant"><a title="Suivant" class="icon-right carrousel_link" href="#"></a></li><li class="carrousel_link filtre_input"><a href="#filtre_siteweb" class="icon-filtre" title="Rechercher & Filtrer"></a></li></ul>');

       function menu_slider() {

           $('#intro_pc .navigation > ul .compteur_slide').remove();
           $('#carrousel .portfolio_home.show_slide:nth-of-type(' + nbre_slide_parpage + 'n)').each(function () {
               var numindex = $(this).index();
               $('#intro_pc .navigation > ul li.total_slide').before('<li class="compteur_slide"><a href="#' + $(this).attr('id') + '" class="carrousel_link pages">' + ((numindex + 1) / nbre_slide_parpage) + '</a>');
           });
           button_slider();
       }
       menu_slider();
       // Core du carrousel - mouvement et menu

       $('.bottom_intro_home').append('<div class="cck_forms filtre_slide"><div class="cck_label cck_label_text"><label for="portfolio_filtre" >Tapez quelques mots pour affiner</label></div><div class="cck_form cck_form_text cck_text"><input type="text" name="portfolio_filtre" id="filtre_siteweb"><div class="valider"><a id="button_slide_search" class="start_search icon-search"></div></div></div>').find('.filtre_slide').width(largeurblock * 0.9).hide();

       $('.navigation .icon-filtre').on('click', function (event) {
           $('.bottom_intro_home .filtre_slide').toggle();
           return false;
       });

       $('.bottom_intro_home').append('<div class="resultats_slide"></div>');
       var resultats_slide = $('.resultats_slide', slider_block);

       var b = 0;


       $('#button_slide_search').on('click', function (event) {
           filtre_slide();
       });


       function slideHome() {

           //console.log('b entree slidehome : '+b);
           //console.log('nbslide entree slidehome : '+nbSlide);
           //on defait tout en 1
           $('.navigation a').removeClass('active_slide');

           slide.removeClass('low_result_item').removeClass('previous_slide').removeClass('active_slide').removeClass('next_slide');

           $('.bottom_intro_home .bg').removeClass().addClass('bg gtrick b' + couleur_slider[b + 1]);

           $("#carrousel").removeClass('low_result').animate({
               left: -(($(window).width() / 3) * b)
           }, 800);
           /*
               $('.navigation a').filter('[href=#slider_pc_'+(b+1)+']').addClass('active_slide');*/
           if (nbSlide == 1) {
               $('.navigation li.total_slide').html('<span>1 / ' + nbSlide + '</span>');
           } else {
               $('.navigation li.total_slide').html('<span>' + (b + 2) + ' / ' + nbSlide + '</span>');
           }

           if (nbSlide >= 2) {
               slide.filter('.show_slide[id=slider_pc_' + (b + 2) + ']').addClass('active_slide');
               slide.filter('.show_slide[id=slider_pc_' + (b + 1) + ']').addClass('previous_slide');
               slide.filter('.show_slide[id=slider_pc_' + (b + 3) + ']').addClass('next_slide');
           } else {
               clearInterval(initSlide);
               $('.show_slide').addClass('active_slide').parent().addClass('low_result').children().addClass('low_result_item');
               $('#intro_pc .navigation > ul .compteur_slide').remove();
               b = 0;
           }

           if (b == -1) {
               $('.navigation .icon-left').addClass('min_left_slide');
           } else {
               $('.navigation .icon-left').removeClass('min_left_slide');
           }

           var new_slider = index_slider[b++];
           if (b >= (nbSlide - 1)) {
               b = 0;
           }
       }

       slideHome();

       function filtre_slide() {
           var input = $('#filtre_siteweb');
           var item = $('.portfolio_home');
           var val = input.val();
           if (val == '') {
               resultats_slide.empty();
               ///////////
               //ça chie la jpense
               item.addClass('show_slide').fadeIn();
               $('.module_portolio_home .cck_module_list portfolio_home .intro-titre .titre_rea span').removeClass('highlighted');
           }
           var regexp = '\\b(.*)';
           for (var i in val) {
               regexp += '(' + val[i] + ')(.*)';
           }
           regexp += '\\b';
           $('.module_portolio_home .cck_module_list .portfolio_home').show();
           $('.module_portolio_home .cck_module_list .portfolio_home .lien_intro h3').each(function () {
               var span = $(this);
               /*
                           console.log(span);*/
               var resultats = span.text().match(new RegExp(regexp, 'i'));
               if (resultats) {
                   var string = '';
                   /*for(var i in resultats){
                       if(i>0){
                           //if(resultats[i] == val[i]){
                           if((i%2) == 0){
                               string += '<span class="highlighted">'+resultats[i]+'</span>';
                           } else {
                               string += resultats[i];
                           }
                       }
                   }*/
                   span.parent().parent().parent().addClass('show_slide');
                   //$('.navigation', '#intro_pc').empty();
                   //span.empty().append(string);
               } else {
                   span.parent().parent().parent().removeClass('show_slide').fadeOut();
               }
           })

           //console.log('nbSlide : '+$('.portfolio_home.show_slide').length)
           nbSlide = $('.portfolio_home.show_slide').length;
           clearInterval(initSlide);
           initSlide = setTimer();
           b = 0;
           menu_slider();
           slideHome();
           size_slider();
           if ((nbSlide == 0)) {
               resultats_slide.empty().text('Aucun résultat');
           } else if (nbSlide == 1) {
               resultats_slide.empty().text('Vous avez ' + nbSlide + ' résultat');
           } else {
               resultats_slide.empty().text('Vous avez ' + nbSlide + ' résultats');
           }


       }
       $('#filtre_siteweb').keypress(function (e) {
           if (e.which == 13) {
               filtre_slide();
           }
       });

       var initSlide = setTimer();

       function setTimer() {
           var temps = setInterval(slideHome, 3000);
           return temps;
       }

       function stopSlider() {
           clearInterval(initSlide);
       }

       function button_slider() {
           $('.navigation a.pages').click(function () {
               clearInterval(initSlide);
               initSlide = setTimer();
               if ($(this).parent().index() == b) {
                   return false;
               } else {
                   b = (($(this).parent().index() * nbre_slide_parpage) - 2);
                   slideHome();
               }
               return false;
           });
       }
       $('.navigation a.icon-left').click(function () {
           clearInterval(initSlide);
           initSlide = setTimer();
           if (b == 0) {
               return false;
           } else {
               b -= 2;
               slideHome();
           }
           return false;
       });

       $('.navigation a.icon-right').click(function () {
           clearInterval(initSlide);
           initSlide = setTimer();
           if (b >= (nbSlide - 1)) {
               b = 0;
               return false;
           } else {
               slideHome();
           }
           return false;
       });

    };

    /* =============== export public methods =============== */
    return {
        init: init
    };

})(jQuery);